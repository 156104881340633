export default (livewireData, list = [], defaultValue = '', valueIfEmpty = null, disabled = false) => ({
    open: false,
    value: '',
    defaultValue: defaultValue,
    list: list,
    livewireData: livewireData,
    disabled: disabled,

    /**
     * Initialize the component
     */
    init() {
        this.value = defaultValue.toString();
        this.$watch('value', (value) => {
            this.publish(value);
        });
    },

    /**
     * Detect if should add or remove a value
     * @param {String|Number} val The value to add in the select bag
     */
    assignOrRemoveValue(val) {
        if (this.value !== val) {
            this.assignValue(val);
        } else {
            this.removeValue();
        }
    },

    /**
     * Add a value in the select bag
     * @param {String|Number} val The value to add in the select bag
     */
    assignValue(val) {
        this.value = val;
        this.close();
    },

    /**
     * Remove a value from the select bag
     */
    removeValue() {
        this.value = '';
    },

    /**
     * Checks if a value is already selected
     * @param {String|Number} val The value to check
     */
    isSelected(val) {
        return this.value === val;
    },

    /**
     * @param {any} value Value to publish to Livewire
     */
    publish(value) {
        if (!this.disabled) {
            this.$wire.set(this.livewireData, value ? value : valueIfEmpty);
        }
    },

    /**
     * Reset the values bag.
     */
    reset() {
        this.value = this.defaultValue;
    },

    /**
     * Close the selection box.
     */
    close() {
        this.open = false;
    },
});
